import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import classNames from "classnames";

import { API, API_ROUTER } from "../../api";

import { ArrowButton, CheckBox, RangePicker } from "../../components/UI";
import { ContentBox, Container, Select } from "../../components/UI";
import GameSwitcher from "../../components/game-switcher";

import placeHolder from "../../assets/fake-team.png";
import country from "../../assets/images/flags/Hungary.png";
import verefied from "../../assets/icons/verefied.svg";

import "./LeaderBoard.css";
import Styled from "./style";

const LeaderBoard = () => {
  const [participants, setParticipants] = useState();
  const [filter, setFilter] = useState("tekken");
  const history = useHistory();

  function goToUrl(url) {
    history.push(`id/${url}`);
  }

  useEffect(() => {
    API.request({
      ...API_ROUTER.rating.getRatingTable,
    })
      .then((res) => {
        setParticipants(res.rows);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="rating__container">
      <Styled.RatingCol>
        <Styled.StyledContentBox>
          <Styled.FilterTitle>
            <FormattedMessage id="ratings.addFilters" tagName="span" />
          </Styled.FilterTitle>
          <Styled.FilterBox>
            <div className="filter__raiting-title">
              <FormattedMessage id="ratings.geo" />
            </div>
            <div className="form-group">
              <FormattedMessage
                id="global.forms.labels.country"
                tagName="label"
              />
              <Select clearable />
            </div>
            <div className="form-group">
              <FormattedMessage
                id="global.forms.labels.region"
                tagName="label"
              />
              <Select clearable />
            </div>
            <div className="form-group">
              <FormattedMessage id="global.forms.labels.city" tagName="label" />
              <Select clearable />
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id="global.forms.labels.distance" />:{" "}
              </label>
              <RangePicker
                hideLeftHandler
                min={0}
                max={100}
                step={1}
                onChange={(value) => {}}
              />
            </div>
            )
          </Styled.FilterBox>
          <Styled.FilterBox>
            <div className="filter__raiting-title">
              <FormattedMessage id="ratings.demography" />
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id="global.forms.labels.age" />:{" "}
              </label>
              <RangePicker
                min={0}
                max={100}
                step={1}
                onChange={(value) => {}}
              />
            </div>
            <div className="form-group">
              <FormattedMessage
                id="global.forms.labels.language"
                tagName="label"
              />
              <Select clearable />
            </div>
            <div className="form-group">
              <FormattedMessage
                id="global.forms.labels.gender"
                tagName="label"
              />
              <Select clearable />
            </div>
          </Styled.FilterBox>
          <Styled.FilterBox>
            <div className="filter__raiting-title">
              <FormattedMessage id="ratings.type" />
            </div>
            <div className="form-group">
              <FormattedMessage
                id="global.forms.labels.progamer"
                tagName="label"
              />
              <Select clearable />
            </div>
            <div className="form-group">
              <FormattedMessage
                id="global.forms.labels.streaming"
                tagName="label"
              />
              <Select clearable />
            </div>
          </Styled.FilterBox>
          <Styled.FilterBox>
            <ArrowButton
              label={<FormattedMessage id="ratings.clearFilters" />}
              variant="secondary"
            />
          </Styled.FilterBox>
        </Styled.StyledContentBox>
      </Styled.RatingCol>
      <section className={classNames("leaderbordBg", "leaderbord")}>
        <form action="" className="leaderbord__form">
          {/* <div className="leaderbord__form-competition-rank">
            <input
              className="form-competition-rank__input"
              type="radio"
              name="competition-rank"
              value="regional"
              id="competition-rank__regional"
            />
            <label
              className="form-competition-rank__label"
              htmlFor="competition-rank__regional"
            >
              regional
            </label>
            <input
              className="form-competition-rank__input"
              type="radio"
              name="competition-rank"
              value="national"
              id="competition-rank__national"
            />
            <label
              className="form-competition-rank__label"
              htmlFor="competition-rank__national"
            >
              national
            </label>
            <input
              className="form-competition-rank__input"
              type="radio"
              name="competition-rank"
              value="continental"
              id="competition-rank__continental"
            />
            <label
              className="form-competition-rank__label"
              htmlFor="competition-rank__continental"
            >
              continental
            </label>
          </div> */}
          {/* <ul className="leaderbord__form-game-list">
            <li className="leaderbord__form-game">
              <input
                className="form-game__input"
                type="radio"
                id="form-game__pubg"
                name="form-game"
                value="pubg"
              />
              <label className="form-game__label" htmlFor="form-game__pubg">
                PUBG
              </label>
            </li>
            <li className="leaderbord__form-game">
              <input
                className="form-game__input"
                type="radio"
                id="form-game__fifa"
                name="form-game"
                value="fifa"
              />
              <label className="form-game__label" htmlFor="form-game__fifa">
                FIFA
              </label>
            </li>
            <li className="leaderbord__form-game">
              <input
                className="form-game__input"
                type="radio"
                id="form-game__tekken"
                name="form-game"
                value="tekken"
                defaultChecked
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
              />
              <label className="form-game__label" htmlFor="form-game__tekken">
                TEKKEN 7
              </label>
            </li>
            <li className="leaderbord__form-game">
              <input
                className="form-game__input"
                type="radio"
                id="form-game__guilty-gear"
                name="form-game"
                value="guilty gear"
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
              />
              <label
                className="form-game__label"
                htmlFor="form-game__guilty-gear"
              >
                Rocket League
              </label>
            </li>
          </ul> */}
        </form>

        <ul className="leaderbord__result-list">
          <div className="leaderbord__game-filter">
            <div className="leaderbord__game">
              <div>
                <FormattedMessage id="createTournament.title.titleGames.placeholder" />
                :
              </div>
              <GameSwitcher
                selectedGame="Dota2"
                gamesAvailable={["dota2", "CS:GO", "FIFA 22"]}
              />
            </div>
          </div>
          <li className="leaderbord__result-list-item leaderbord__result-list-item--header">
            <p className="leaderbord__result-team">
              <FormattedMessage id="teams.team.table.team" />
            </p>
            <p className="leaderbord__result-pts">
              {" "}
              <FormattedMessage id="global.forms.labels.PTS" />
            </p>
            <p className="leaderbord__result-win">W</p>
            <p className="leaderbord__result-lose">D</p>
            <p className="leaderbord__result-percent">%</p>
            {/* <p className="leaderbord__result-pts">C</p> */}
            <p className="leaderbord__result-flag">
              <FormattedMessage id="global.forms.labels.flag" />
            </p>
            <p className="leaderbord__result-pts">Status</p>

            {/* <p className="leaderbord__result-infos-link">
              <FormattedMessage id="idCard.info" />
            </p> */}
          </li>

          {participants?.map((el) => (
            <li
              className={
                participants?.indexOf(el) % 2 == 0
                  ? "leaderbord__result-list-item leaderbord__result-list-item--also-gray"
                  : "leaderbord__result-list-item leaderbord__result-list-item--gray"
              }
            >
              <p
                className={
                  participants?.indexOf(el) == 0
                    ? "leaderbord__result-place id-first"
                    : participants?.indexOf(el) == 1
                    ? "leaderbord__result-place id-second"
                    : participants?.indexOf(el) == 2
                    ? "leaderbord__result-place id-third"
                    : "leaderbord__result-place"
                }
              >
                {participants?.indexOf(el) + 1}
              </p>
              <p className="leaderbord__result-emblem">
                <img
                  src={el.logo ? el.logo : placeHolder}
                  alt={el.logo ? el.logo : placeHolder}
                  width="28"
                  height="28"
                />
              </p>

              <p
                className="leaderbord__result-tag"
                style={{ cursor: "pointer" }}
                onClick={() => goToUrl(el.userUrl)}
              >
                {el.userNickname.length > 8
                  ? el.userNickname.slice(0, 8) + "..."
                  : el.userNickname}{" "}
              </p>

              <p className="leaderbord__result-pts">{el.value}</p>
              <p className="leaderbord__result-win">-</p>
              <p className="leaderbord__result-lose">-</p>
              <p className="leaderbord__result-percent">
                {/* {(el.win / (el.win + el.lose)).toFixed(2)} */} -
              </p>
              {/* <p className="leaderbord__result-pts">{el.country}</p> */}

              <p className="leaderbord__result-flag">
                <img
                  src={el.flag ? el.flag : country}
                  alt=""
                  width="29"
                  height="19"
                />
              </p>
              <p style={{ color: "orange" }} className="leaderbord__result-pts">
                <img src={verefied} alt={verefied} width="20" height="20" />
                <br />
                PRO
              </p>

              {/* <a
                href={`id/${el?.userUrl}`}
                className="leaderbord__result-infos-link"
              >
                <FormattedMessage id="idCard.info" /> ...
              </a> */}
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default LeaderBoard;
