import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { useDraggable } from "react-use-draggable-scroll";

import noImage from "../../../../assets/images/no-image.webp";

import styles from "./styles.module.scss";

export const MatchMembersFFA = ({ match }) => {
  const history = useHistory();
  const dragRef = useRef();
  const { events } = useDraggable(dragRef);

  const [membersState, setMembersState] = useState([]);
  // если участник - команда, то выводим название команды, если нет, то никнейм
  const membersData = (member) => {
    if (member?.team === null) {
      return (
        <td
          onClick={(e) => goToUrl(`/id/${member?.user?.url}`)}
          className={classNames("bracketFFANameHover", styles.name)}
        >
          <span className={styles.logo}>
            <img src={member?.user?.avatar ? member?.user?.avatar : noImage} />
          </span>
          {member?.user?.nickname}
        </td>
      );
    } else if (member?.user === null) {
      return (
        <td
          onClick={(e) => goToUrl(`/teams/team/${member?.team?.id}`)}
          className={classNames("bracketFFANameHover", styles.name)}
        >
          <span className={styles.logo}>
            <img src={member?.team?.logo ? member?.team?.logo : noImage} />
          </span>
          {member?.team?.name}
        </td>
      );
    } else {
      return (
        <td className={classNames("bracketFFANameHover", styles.name)}>
          <span className={styles.logo}>
            <img src={noImage} />
          </span>
          name
        </td>
      );
    }
  };
  const goToUrl = (url) => {
    history.push(url);
  };

  useEffect(() => {
    setMembersState(match?.stageFfa?.matchFfaMembers);
  }, [match?.stageFfa?.matchFfaMembers]);
  return (
    <>
      <div
        {...events}
        ref={dragRef}
        className={classNames("matchMembersWrapperBg", styles.detailWrap)}
      >
        <div className={styles.scrollWrap}>
          <div className={styles.tableWrap}>
            <table
              className={classNames("bracketTableFFAColors", styles.table)}
            >
              <caption>MATCH MEMBERS FFA</caption>
              <colgroup>
                <col className={styles.col_team} />
                <col className={styles.col_space} />
                <col className={styles.col_placement} />
                <col className={styles.col_kills} />
                <col className={styles.col_winLose} />
                <col className={styles.col_total} />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col" colSpan={2}>
                    TEAM
                  </th>
                  <th scope="col">PLACEMENT</th>
                  <th scope="col">KILLS</th>
                  <th scope="col">WIN / LOSE</th>
                  <th scope="col">TOTAL</th>
                </tr>
              </thead>
              <tbody>
                {membersState?.map((el, index) => (
                  <tr key={el?.id}>
                    <td>#{index + 1}</td>

                    {membersData(el?.tournamentMember)}

                    <>
                      <td>{el?.placement}</td>
                      <td>{el?.kills}</td>
                      {match?.status === "soon" ? (
                        <td>soon</td>
                      ) : (
                        <td>{el?.isWinner === true ? "Win" : "Lose"}</td>
                      )}
                    </>

                    <td>{el?.totalScore}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default MatchMembersFFA;
