import placeHolder from "../../../../assets/fake-team.png";

import styles from "./styles.module.scss";

const imageWithPlaceHolder = (logo) => {
  return (
    <img
      className={styles.styledCard__image}
      src={logo ? logo : placeHolder}
      alt="img"
    />
  );
};
const dumb = () => {
  console.log("there is no action on this card");
};
export default function Card({
  add = false,
  title,
  isComing = false,
  role,
  logo,
  action = dumb,
  name,
  id,
}) {
  const NameOfCard = (value) => {
    return <p>{value.length > 15 ? value.slice(0, 15) + "..." : value}</p>;
  };
  return (
    <div className={styles.styledCard} key={id}>
      <section
        className={styles.styledCard__header}
        onClick={() => {
          role && (window.location.href = `/teams/team/${id}`);
          !isComing ? action() : dumb();
        }}
      >
        {/* if add place PLUS else place image */}

        {add ? (
          <i className="icon icon-plus" style={{ background: "var(--icon)" }} />
        ) : (
          imageWithPlaceHolder(logo)
        )}
      </section>
      <section className={styles.styledCard__footer}>
        {/* Main user */}
        {!!name && NameOfCard(name ? name : "")}

        {!!title && NameOfCard(title)}
      </section>
    </div>
  );
}
