import { API_ROUTER } from "../../../api";

export const MATCHES_FILTER = [
  { id: 0, value: "Show all matches" },
  {
    id: 1,
    value: "Show my matches",
  },
];
export const MATCHES_FILTER_PIBLIC = [
  {
    id: 0,
    value: "Show all matches",
  },
];

export const MATCHES_STATE_FILTER = [
  { id: "soon", value: "soon" },
  { id: "ended", value: "ended" },
  { id: "live", value: "live" },
  { id: "canceled", value: "canceled" },
];

export const MATCHES_STATUS_FILTER = [
  { id: "future", value: "future" },
  { id: "current", value: "current" },
  { id: "past", value: "past" },
  { id: "canceled", value: "canceled" },
];
