import ImagesComponent from "./components/images-row";
import TeamContactsComponent from "./components/team-contacts-row";

import styles from "./styles.module.scss";

const CommunityBody = ({ community }) => {
  return (
    <div className={styles.events__about__wrapper}>
      <section className={styles.events__about}>
        {!!community?.organizerLogo?.length && (
          <ImagesComponent
            organizerLogo={true}
            images={community?.organizerLogo}
          />
        )}

        {!!community?.teamOfOrganizer?.length && (
          <TeamContactsComponent
            teamOfOrganizer={true}
            information={community?.teamOfOrganizer}
          />
        )}
        {!!community?.contact?.length && (
          <TeamContactsComponent
            contacts={true}
            information={community?.contact}
          />
        )}

        {!!community?.partnerLogo?.length && (
          <ImagesComponent partnerLogo={true} images={community?.partnerLogo} />
        )}
        {!!community?.sponsorLogo?.length && (
          <ImagesComponent sponsorLogo={true} images={community?.sponsorLogo} />
        )}
        {!!community?.anyPhotos?.length && (
          <ImagesComponent anyPhotos={true} images={community?.anyPhotos} />
        )}
      </section>
    </div>
  );
};

export default CommunityBody;
