import React, { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useLocation, useParams } from "react-router-dom";

import CupTab from "../CupTab";
import Group_stage from "./bracketTabs/group_stage-component/group_stage";
import PlayOff from "./bracketTabs/PlayOff";

import s from "./CupBracket.module.scss";
import { useState } from "react";
import { API, API_ROUTER } from "../../api";
import {
  DOUBLE_ELIMINATION_TYPE,
  GRAND_FINAL_TYPE,
} from "./bracketTabs/PlayOff/constants";
import { Preloader } from "../UI";

export default function CupBracket(props) {
  const { tournament } = props;
  let { id } = useParams();
  const { pathname } = useLocation();
  const [gridData, setGridData] = useState(null);
  const [grandFinal, setGrandFinal] = useState(null);
  const [tournamentMember, setTournamentMember] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [navList, setNavList] = useState([]);

  const selectTab = (fn1, fn2) => {
    if (pathname === `/battleCup/${id}/bracket/group_stage`) {
      return <Group_stage {...props} getGroupStageData={fn2} />;
    } else if (pathname === `/battleCup/${id}/bracket/play_off`) {
      return (
        <PlayOff
          requestData={gridData}
          grandFinal={grandFinal}
          tournamentMember={tournamentMember}
        />
      );
    }
  };

  const getTournamentData = async () => {
    setIsLoad(true);
    await API.request({
      ...API_ROUTER.tournaments.getTournamentData,
      pathKeys: {
        id: id,
      },
    })
      .then((res) => {
        setGridData(res);
        if (res?.type === DOUBLE_ELIMINATION_TYPE) {
          const grandFinal = res?.basket?.find(
            (el) => el?.type === GRAND_FINAL_TYPE
          );
          setGrandFinal(grandFinal);
        }

        setTournamentMember(res?.tournamentMember);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoad(false));
  };

  const setNavigation = () => {
    setNavList([
      {
        id: "GROUP STAGE",
        title: "GROUP STAGE",
        to: `/battleCup/${id}/bracket/group_stage`,
        isShow: tournament?.stages?.length > 1,
      },
      {
        id: "PLAY OFF",
        title: "PLAY OFF",
        to: `/battleCup/${id}/bracket/play_off`,
        isShow: !!gridData,
      },
    ]);
  };

  const splitIntoSubarray = (size, currentArray, newArray, name) => {
    for (let i = 0; i < Math.ceil(currentArray.length / size); i++) {
      newArray.push({
        [name]: currentArray.slice(i * size, i * size + size),
        id: i + 1,
      });
    }
    return newArray;
  };

  const makeNewArrayFromRowsTournamentMember = (
    rowsArray,
    tournamentMemberArray
  ) => {
    const newTournamentMemberArray = JSON.parse(
      JSON.stringify(tournamentMemberArray)
    );
    const newRols = [];

    rowsArray.rows.map((u) => {
      if (!u?.tournamentMemberId) {
        return;
      }
      const value = +u.tournamentMemberId - 1;
      if (newTournamentMemberArray[value]?.user) {
        newTournamentMemberArray[value].user.score = u.score;
        newTournamentMemberArray[value].user.number = u.position;
      } else {
        if (newTournamentMemberArray[value]?.team) {
          newTournamentMemberArray[value].team.score = u.score;
          newTournamentMemberArray[value].team.number = u.score;
        }
      }

      newRols.push(newTournamentMemberArray[value]);
    });
    return newRols;
  };

  const getData = (data, tournamentMember) => {
    const copyData = JSON.parse(JSON.stringify(data));
    //Копируем объект с информацией о турнирной сетке

    for (let i = 0; i < copyData.cols.length; i++) {
      copyData.cols[i].newRols = [];
      const newRols = [];
      //Создаём новый массив раундов

      let height = 200;
      if (i > 0 && copyData.cols[i].rows.length > 0) {
        height = parseInt(copyData.cols[i - 1].teamsBlockStyle.height) * 1.64;
      }
      copyData.cols[i].teamsBlockStyle = {
        height: `${height}px`,
      };
      //Рассчитываем высоту teamsBlock

      newRols.push(
        ...makeNewArrayFromRowsTournamentMember(
          copyData.cols[i],
          tournamentMember
        )
      );
      //Формируем новый массив из данных rows и tournamentMember

      const turnament = [];
      let group = [];
      splitIntoSubarray(2, newRols, turnament, "turnament");
      splitIntoSubarray(2, turnament, group, "group");
      //Разбиваем на подгруппы и турниры (это нужно для отрисокви)

      copyData.cols[i].newRols = group;
      //Присваиваем готовые значения
    }
    return copyData;
  };

  useEffect(() => {
    getTournamentData();
  }, []);

  useEffect(() => {
    setNavigation();
  }, [gridData, tournament?.stages?.length]);

  return (
    <div className={s.bracket}>
      {!!isLoad && <Preloader />}

      {!isLoad && (
        <>
          {!!navList.length && <CupTab navList={navList} showAll={false} />}

          {selectTab(getData, makeNewArrayFromRowsTournamentMember)}
        </>
      )}
    </div>
  );
}
