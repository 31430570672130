import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import classNames from "classnames";

import { getUserData } from "../../../../redux/actions";
import { API, API_ROUTER } from "../../../../api";

import getPublicGames from "../../../../helpers/games/getPublicGames";
import getUserTournaments from "../../../../helpers/userTournaments/loadUserTournaments";

import { getGameCards, getTeamCard, getTournamentCard } from "../../const";

import {
  TeamsRow,
  GamesRow,
  StatisticsRow,
  TournamentsRow,
} from "../../../../components/profile-bricks";
import {
  InnerBox,
  ProgressCircle,
  Button,
  ContentBox,
} from "../../../../components/UI";
import GameSwitcher from "../../../../components/game-switcher";

import Styled, { StyledRow } from "../../style";

export const FILTER_VALUE_SHOW_MY_MATCHES = "2";

const Bottom = ({ user, history, isCreator, id }) => {
  const [games, setGames] = useState([]);
  const [userPublicGames, setUserPublicGames] = useState([]);
  const [teams, setTeams] = useState([]);
  const [teamFilter, setTeamFilter] = useState("");
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [userTournaments, setUserTournamets] = useState([]);
  const [selectedGame, setSelectedGame] = useState(
    <FormattedMessage id="settings.games.chooseGame" />
  );
  const [selectedGameStorageFilter, setSelectedGameStorageFilter] = useState(
    {}
  );

  const onGameChange = (game) => {
    setSelectedGame(game);
  };

  function goToUrl(url) {
    history.push(url);
    localStorage.setItem("matchFilter", FILTER_VALUE_SHOW_MY_MATCHES);
    localStorage.setItem(
      "matchFilterGame",
      JSON.stringify(selectedGameStorageFilter)
    );
  }

  const getPublicUserGames = async () => {
    setRequestSuccess(false);
    if (id === undefined) {
      return;
    }
    await getPublicGames(id)
      .then((res) => {
        setUserPublicGames(res.userGames);
      })
      .catch();
  };

  const getOwnerUserGames = async () => {
    setRequestSuccess(false);
    if (id === undefined) {
      return;
    }
    await API.request({
      ...API_ROUTER.games.getUserGames,
    })
      .then((res) => {
        setGames(res.games);
      })
      .catch();
  };

  const getUserTeamsByRole = async () => {
    if (id === undefined) {
      return;
    }
    setRequestSuccess(false);
    await API.request({
      ...API_ROUTER.teams.getTeamByRole,
      pathKeys: {
        userId: id,
        role: "owner",
      },
    })

      .then((res) => {
        setTeams(res.teams);
      })
      .catch();
  };

  const getTournaments = async () => {
    setRequestSuccess(false);
    if (id === undefined) {
      return;
    }
    await getUserTournaments(id)
      .then((res) => {
        setUserTournamets(res.tournaments);
      })
      .catch()
      .finally(() => setRequestSuccess(true));
  };

  useEffect(() => {
    if (!id || !teamFilter) {
      return;
    }
    API.request({
      ...API_ROUTER.teams.getTeamByRole,
      pathKeys: {
        userId: id,
        role: teamFilter,
      },
    })
      .then((res) => {
        setTeams(res.teams);
      })
      .catch();
  }, [teamFilter, id]);

  useEffect(() => {
    let gamesList;
    isCreator()
      ? (gamesList = games?.map((el) => el.game))
      : (gamesList = userPublicGames?.map((el) => el));

    gamesList.map((el) => {
      if (selectedGame === el.title) {
        setSelectedGameStorageFilter({ id: el.id, value: el.title });
      }
    });
  }, [selectedGame]);

  useEffect(() => {
    getUserData();
    getPublicUserGames();
    getOwnerUserGames();
    getUserTeamsByRole();
    getTournaments();
  }, []);
  {
  }
  return (
    <div>
      <ContentBox className={classNames("contentBoxColor")}>
        <GamesRow
          title={
            isCreator() ? (
              <FormattedMessage id="id.myGames" />
            ) : (
              <FormattedMessage id="id.games" />
            )
          }
          cards={
            isCreator()
              ? getGameCards(games, history, isCreator())
              : getGameCards(userPublicGames, history, isCreator())
          }
          requestSuccess={requestSuccess}
        />
      </ContentBox>
      <ContentBox className={classNames("contentBoxColor")}>
        <TeamsRow
          title={
            isCreator() ? (
              <FormattedMessage id="more.myTeams" />
            ) : (
              <FormattedMessage id="navigation.teams" />
            )
          }
          teamRolesOwner={<FormattedMessage id="id.owner" />}
          teamRolesMember={<FormattedMessage id="id.member" />}
          setTeamFilter={setTeamFilter}
          requestSuccess={requestSuccess}
          cards={getTeamCard(teams, history, isCreator())}
        />
      </ContentBox>

      <ContentBox className={classNames("contentBoxColor")}>
        <TournamentsRow
          title={
            isCreator() ? (
              <FormattedMessage id="id.myTournaments" />
            ) : (
              <FormattedMessage id="id.tournaments" />
            )
          }
          requestSuccess={requestSuccess}
          cards={getTournamentCard(userTournaments, history, isCreator())}
        />
      </ContentBox>

      {!!isCreator() && (
        <ContentBox className={classNames("contentBoxColor")}>
          <StatisticsRow
            title={
              isCreator()
                ? "Followed"
                : // <FormattedMessage id="id.myStatistics" />
                  // <FormattedMessage id="battlecup.tabs.statistics" />
                  "Followed"
            }
            requestSuccess={requestSuccess}
            cards={getStatisticCard(user, history, id)}
          />
        </ContentBox>
      )}
      <ContentBox className={classNames("contentBoxColor")}>
        <StyledRow>
          <section>
            <h1>
              <FormattedMessage id="id.performance" /> :
            </h1>
            <GameSwitcher
              selectedGame={selectedGame}
              gamesAvailable={
                isCreator()
                  ? games?.map((el) => el.game.title)
                  : userPublicGames?.map((el) => el.game)
              }
              setSelectedGame={onGameChange}
            />
          </section>
          <div style={{ marginTop: "20px" }}>
            <Styled.PerformanceRow>
              <Styled.PerformanceCol>
                <Styled.PerformanceData>
                  <div
                    className="profile__perfomance-matches-button"
                    onClick={() => goToUrl("/watch")}
                  >
                    <FormattedMessage id="id.performance.game.matches" />
                  </div>
                  <div>0</div>
                </Styled.PerformanceData>
                <Styled.PerformanceData>
                  <div className="profile__perfomance-matches-button">
                    <FormattedMessage id="id.performance.officialMatches" />
                  </div>
                  <div>0</div>
                </Styled.PerformanceData>
              </Styled.PerformanceCol>
              <Styled.PerformanceCol>
                <Styled.PerformanceData>
                  <div className="profile__perfomance-matches-button">
                    <FormattedMessage id="id.performance.wins" />
                  </div>
                  <div>0</div>
                </Styled.PerformanceData>
                <Styled.PerformanceData>
                  <div className="profile__perfomance-matches-button">
                    <FormattedMessage id="id.performance.defeats" />
                  </div>
                  <div>0</div>
                </Styled.PerformanceData>
              </Styled.PerformanceCol>
            </Styled.PerformanceRow>
            <Styled.PerformanceControls>
              <div style={{ margin: "20px 20px " }}>
                <Button
                  label={<FormattedMessage id="id.performance.seeInRating" />}
                  action={() => history.push(`/rating`)}
                />
              </div>
            </Styled.PerformanceControls>
          </div>
        </StyledRow>
      </ContentBox>
      <ContentBox className={classNames("contentBoxColor")}>
        <StyledRow>
          <section>
            <h1>
              <FormattedMessage id="id.favoriteGame" />:{" "}
            </h1>
            <GameSwitcher
              selectedGame={selectedGame}
              gamesAvailable={
                isCreator()
                  ? games?.map((el) => el.game.title)
                  : userPublicGames?.map((el) => el.game)
              }
              setSelectedGame={onGameChange}
            />
          </section>
        </StyledRow>
        <Styled.ProgressRow>
          <Styled.ProgressCol>
            <InnerBox>
              <Styled.ProgressTitle>
                <FormattedMessage id="id.performance.cs.matchesPlayed" />
              </Styled.ProgressTitle>
              <ProgressCircle value={"0"} fakeProgress />
            </InnerBox>
          </Styled.ProgressCol>

          <Styled.ProgressCol>
            <InnerBox>
              <Styled.ProgressTitle>
                <FormattedMessage id="id.performance.cs.roundsPlayed" />
              </Styled.ProgressTitle>
              <ProgressCircle value={"0"} fakeProgress />
            </InnerBox>
          </Styled.ProgressCol>
          <Styled.ProgressCol>
            <InnerBox>
              <Styled.ProgressTitle>
                <FormattedMessage id="id.performance.dota.winRate" />
              </Styled.ProgressTitle>
              <ProgressCircle value={0} />
            </InnerBox>
          </Styled.ProgressCol>
        </Styled.ProgressRow>
      </ContentBox>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    runGetUserData: () => dispatch(getUserData()),
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Bottom));

function getStatisticCard(user, history, id) {
  const statisticCard1 = [
    // {
    //   id: uuidv4(),
    //   add: false,
    //   title: "Roster",
    //   action: () => {
    //     history.push("/rating");
    //   },
    //   img: "",
    //   icon: "user-group",
    //   isComing: true,
    // },
    // {
    //   id: uuidv4(),
    //   add: false,
    //   title: <FormattedMessage id="id.statistics.all" />,
    //   action: () => {
    //     history.push("/rating");
    //   },
    //   img: "",
    //   icon: "graphic",
    //   isComing: true,
    // },
    {
      id: uuidv4(),
      add: false,
      // title: <FormattedMessage id="id.followed" />,
      title: "Communities",
      action: () => {
        history.push("/followed/community");
      },
      img: "",
      icon: "heart",
      isComing: false,
    },
    {
      id: uuidv4(),
      add: false,
      // title: <FormattedMessage id="id.followed" />,
      title: "Events",
      action: () => {
        history.push("/followed/events");
      },
      img: "",
      icon: "heart",
      isComing: false,
    },
    {
      id: uuidv4(),
      add: false,
      // title: <FormattedMessage id="id.followed" />,
      title: "Tournaments",
      action: () => {
        history.push("/followed/tournaments");
      },
      img: "",
      icon: "heart",
      isComing: false,
    },
    {
      id: uuidv4(),
      add: false,
      // title: <FormattedMessage id="id.followed" />,
      title: "Users",
      action: () => {
        history.push("/followed/users");
      },
      img: "",
      icon: "heart",
      isComing: false,
    },
  ];

  return statisticCard1;
}
