import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import classNames from "classnames";

import defaultLogo from "../../assets/avatars/avatar.jpg";
import s from "./playersContainer.module.scss";
const PlayersContainer = (props) => {
  const [btnCheck, setBtnCheck] = useState(false);
  const changeCountPlayers = (boolean, id, nickname, gamerole) => {
    setBtnCheck(!boolean);
    if (btnCheck) {
      props.setCountPlayers(props.countPlayers - 1);
      const newArray = props.teamRosterMembers.filter((el) => el.userId !== id);
      props.setTeamRosterMembers(newArray);
    } else {
      props.setCountPlayers(props.countPlayers + 1);
      const newObj = {};
      newObj.userId = id;
      newObj.nickname = nickname;
      newObj.role = gamerole;

      const newArray = JSON.parse(JSON.stringify(props.teamRosterMembers));
      newArray.push(newObj);
      props.setTeamRosterMembers(newArray);
    }
  };
  const history = useHistory();
  function goToUrl(url) {
    history.push(url);
  }
  function showToast() {
    toast.success(
      "The player must fill in all the required fields in the profile settings"
    );
  }
  return (
    <div className={s.playersContainer}>
      <div className={s.playerCard}>
        <div
          className={s.leftContainer}
          onClick={() => {
            goToUrl(`/id/${props.url}`);
          }}
        >
          <div className={s.imgContainer}>
            <img src={props.avatar || defaultLogo} alt="logo" />
          </div>
          <div className={s.textContainer}>
            <div className={s.role}>
              {props.gamerole === "capitanTeam" ? "Captain" : "Player"}
            </div>
            <div className={s.subtitle}>{props.nickname}</div>
          </div>
        </div>
        {props.profileInfoFilled ? (
          props.btn ? (
            <div
              className={
                btnCheck
                  ? classNames("checkMarkPlayerBg", s.checkMark, s.btn)
                  : classNames("plusButtonBg", s.plus, s.btn)
              }
              onClick={() =>
                changeCountPlayers(
                  btnCheck,
                  props.id,
                  props.nickname,
                  props.gamerole
                )
              }
            ></div>
          ) : (
            <div className={s.plusMini}>+</div>
          )
        ) : (
          <div className={s.plusMini}>
            {" "}
            <i
              className="icon icon-exclamation-mark"
              style={{ background: "#f5d3a4" }}
              onClick={() => showToast()}
            ></i>
          </div>
        )}
      </div>
    </div>
  );
};
export default PlayersContainer;
