import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Link, useParams } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { CircularProgress } from "@material-ui/core";

import { API, API_ROUTER } from "../../api";

import { getUserData } from "../../redux/actions";

import { ROUTER } from "../../config";

import PlayersContainer from "../../components/players-container";
import TeamContainer from "../../components/team-container";
import getUserTeams from "../../helpers/userTeams/getUserTeams";

import s from "./registrationTournament.module.scss";

const RegistrationTournament = ({ userData }) => {
  const [countPlayers, setCountPlayers] = useState(0);
  const [teamRosterMembers, setTeamRosterMembers] = useState([]);
  const [userTeams, setUserTeams] = useState(null);
  const [teamId, setTeamId] = useState();
  const [teamName, setTeamName] = useState();
  const [currentTeam, setCurrentTeam] = useState(false);
  const [flag, setFlag] = useState();
  const [requestSuccess, setRequestSuccess] = useState(false);

  const [data, setData] = useState(null);
  const [sizeTeam, setSizeTeam] = useState(0);
  let { id } = useParams();
  const tournamentGameId = data?.game?.id;
  useEffect(() => {
    if (userData?.id === undefined) {
      return;
    }
    API.request({
      ...API_ROUTER.tournaments.getCurrentTournament,
      pathKeys: {
        tournamentId: id,
      },
    })
      .then((res) => {
        setData(res);
        setSizeTeam(+res.size.split("x")[0]);
        /* console.log(res); */
      })
      .catch((err) => console.log(err));
    API.request({
      ...API_ROUTER.teams.getTeamByRole,
      pathKeys: {
        userId: userData?.id,
        role: "owner",
      },
    })
      .then((res) => {
        setUserTeams(res);
        setRequestSuccess(true);
      })
      .catch((err) => console.log(err));
    // getUserTeams()
    //   .then((res) => setUserTeams(res))
    //   .catch((err) => console.log(err));
  }, [userData?.id, id]);

  const submitTeamRoster = () => {
    API.request({
      ...API_ROUTER.tournaments.postTeamRoster,
      pathKeys: {
        tournamentId: id,
      },
      data: {
        team: {
          id: teamId,
          name: teamName,
          members: [...teamRosterMembers],
        },
        user: null,
      },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={s.registrationContainer}>
      <div className={s.contentContainer}>
        <div className={s.title}>Registration Form</div>

        <div className={classNames("registrTournamentInfoBg", s.teamInfo)}>
          <div className={s.teamTitle}>{data?.name}</div>
          <div className={s.buttomContainerInfo}>
            <div className={s.name}>{data?.game.title}</div>
            <div className={s.vs}>
              {sizeTeam} vs {sizeTeam}
            </div>
          </div>
        </div>
        <div className={s.addContainer}>
          <div className={s.rosterContainer}>
            <div className={s.rosterTitle}>Main Roster</div>
            <div className={s.rosterSubtitle}>
              Roster {sizeTeam}\{countPlayers}
            </div>
          </div>
          {/* <div className={s.addPlayer}>Add Player</div> */}
        </div>

        <div
          className={classNames("registerTournamentPlayersRowBg", s.playerRow)}
        >
          <div className={s.rowTitle}>Teams</div>
        </div>
        {flag !== false ? (
          requestSuccess ? (
            <>
              {userTeams?.teams.map((el) => (
                <TeamContainer
                  key={el.id}
                  gameId={tournamentGameId}
                  id={el.id}
                  name={el.name}
                  role={el.role}
                  logo={el.logo}
                  btn={true}
                  setCountPlayers={setCountPlayers}
                  countPlayers={countPlayers}
                  setTeamRosterMembers={setTeamRosterMembers}
                  teamRosterMembers={teamRosterMembers}
                  setTeamId={setTeamId}
                  setTeamName={setTeamName}
                  userTeams={userTeams}
                  el={el}
                  setCurrentTeam={setCurrentTeam}
                  setFlag={setFlag}
                />
              ))}
            </>
          ) : (
            <CircularProgress />
          )
        ) : (
          currentTeam?.map((el) => (
            <TeamContainer
              key={el.id}
              id={el.id}
              name={el.name}
              role={el.role}
              logo={el.logo}
              btn={true}
              setCountPlayers={setCountPlayers}
              countPlayers={countPlayers}
              setTeamRosterMembers={setTeamRosterMembers}
              teamRosterMembers={teamRosterMembers}
              setTeamId={setTeamId}
              setTeamName={setTeamName}
              userTeams={userTeams}
              el={el}
              setCurrentTeam={setCurrentTeam}
              setFlag={setFlag}
            />
          ))
        )}

        {countPlayers === sizeTeam ? (
          <Link
            to={`/battleCup/${id}/information`}
            className={s.btnDone}
            style={{ textDecoration: "none" }}
            onClick={() => submitTeamRoster()}
          >
            Registration{" "}
          </Link>
        ) : (
          false
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    runGetUserData: () => dispatch(getUserData()),
  };
};
export default injectIntl(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(RegistrationTournament)
  )
);
