import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import classNames from "classnames";

import { API, API_ROUTER } from "../../../../api";
import { isAuthenticated } from "../../../../helpers";

import styles from "./styles.module.scss";
import AsiaLogo from "../../../../assets/asia-logo.jpeg";
import starImage from "../../../../assets/svg/Star.svg";
import starGoldImage from "../../../../assets/svg/StarGold.svg";

const CommunityItem = ({ id, name, description, follow, logo }) => {
  const history = useHistory();

  const [star, tongleStar] = useState(!follow);

  const followHandler = () => {
    API.request({
      ...API_ROUTER.follow.IsUserFollowCommunity,
      pathKeys: {
        communityId: id,
      },
    })
      .then((res) =>
        res.follow === true
          ? API.request({
              ...API_ROUTER.follow.stopFollowCommunity,
              pathKeys: {
                communityId: id,
              },
            })
              .then((res) => {
                toast.success(res.message);
              })
              .catch((err) => console.log(err))
          : API.request({
              ...API_ROUTER.follow.followCommunity,
              data: {
                communityId: id,
              },
            })
              .then((res) => {
                toast.success(res);
              })
              .catch((err) => console.log(err))
      )
      .catch((err) => console.log(err));
    if (star) {
      tongleStar(false);
    } else tongleStar(true);
  };

  function starHandler(star) {
    if (star) {
      return styles.starInactive;
    } else {
      return styles.starActive;
    }
  }

  function goToUrl(url) {
    history.push(url);
  }

  return (
    <li className={classNames("calendarItemBorder", styles.community)} key={id}>
      <img
        src={logo ? logo : AsiaLogo}
        className={styles.communityLogo}
        onClick={() => goToUrl(`/community/${id}/events`)}
      />
      <div
        className={styles.communityDescription}
        onClick={() => goToUrl(`/community/${id}/events`)}
      >
        <h4 className={styles.communityName}>{name ? name : "Community"}</h4>
        <div className={styles.communityDecoration} />
        <p className={styles.communityDate}>
          {description?.length > 30 ? description?.slice(0, 30) : description}
        </p>
      </div>
      {!!isAuthenticated() && (
        <img
          // If followed Golden else Default
          src={star ? starImage : starGoldImage}
          className={styles.community__star}
          onClick={() => followHandler()}
        />
      )}
    </li>
  );
};

export default CommunityItem;
