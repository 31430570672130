import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";

import { API, API_ROUTER } from "../../api";
import { convertDate } from "../../helpers/dates-formatter";
import NoImage from "../../assets/no-image.png";
import GameItem from "./components/game-item";

import Button from "../../components/UI/buttons/buttons-login_register/button/Button";
import { isAuthenticated } from "../../helpers";

import styles from "./styles.module.scss";

export default function CupInformation({ tournament, ...props }) {
  let { id } = useParams();
  const [userData, setUserData] = useState(null);
  const [isUserDataParicipant, setIsUserDataParicipant] = useState();
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [userGames, setUserGames] = useState();

  const history = useHistory();

  const getMyData = () => {
    API.request({
      ...API_ROUTER.user.getMyData,
    })
      .then((res) => {
        setUserData(res);
        getUserCurrentTournament(res.id, tournament.id);
        setRequestSuccess(true);
      })
      .catch((err) => console.log(err));
  };

  const getUserGames = () => {
    API.request({
      ...API_ROUTER.games.getUserGames,
    })
      .then((res) => {
        setUserGames(res.games);
      })
      .catch((err) => console.log(err));
  };

  const getUserCurrentTournament = (userId, tournamentId) => {
    API.request({
      ...API_ROUTER.tournaments.getUserCurrentTournament,
      pathKeys: {
        userId: userId,
        tournamentId: tournamentId,
      },
    })
      .then((res) => {
        setIsUserDataParicipant(res);
      })
      .catch((err) => console.log(err));
  };

  // ПРОВЕРКА ЕСТЬ ЛИ У ЮЗЕРА ИГРА ТУРНИРА
  const hasUserGame = () => {
    const games = [];
    userGames?.map((i) => games.push(i.game.id));
    return games.includes(tournament?.game.id);
  };

  const goToUrl = (url) => {
    history.push(url);
    if (!isAuthenticated()) {
      localStorage.removeItem("eventId");
      localStorage.removeItem("communityId");
      localStorage.removeItem("profileUrl");
      return localStorage.setItem("tournamentId", id);
    }
  };

  const submitTeamRoster = (tournamentId, userInfo) => {
    API.request({
      ...API_ROUTER.tournaments.postTeamRoster,
      pathKeys: {
        tournamentId: tournamentId,
      },
      data: {
        user: {
          id: userData?.id,
          nickname: userData?.nickname,
        },
        team: null,
      },
    })
      .then((res) => {
        toast.success("Your registration successfully");
      })
      .catch((err) => toast.success("Something went wrong"));
  };

  const checkParticipationTypeData = (type, fn) => {
    type === "user"
      ? submitTeamRoster(id, userData)
      : fn(`/battleCup/${tournament?.id}/registrationTournament`);
  };

  useEffect(() => {
    getMyData();
    getUserGames();
  }, []);

  return (
    <div className={styles.wrapper}>
      {tournament?.registrationStartedAt &&
        tournament?.registrationEndedAt && (
          <section className={classNames("cupInfoBg", styles.regPeriod)}>
            <div></div>
            <div>
              <p>
                <FormattedMessage id="battlecup.information.registrationPeriod" />
              </p>
              <p>
                {convertDate(tournament?.registrationStartedAt)} -{" "}
                {convertDate(tournament?.registrationEndedAt)}
              </p>
            </div>
          </section>
        )}{" "}
      {!!tournament?.details?.description ? (
        <section className={classNames("cupInfoBg", styles.tourDetails)}>
          <div>
            <h1>
              <FormattedMessage id="battlecup.information.tournamentDetails" />
            </h1>
            {tournament?.details?.description ? (
              <p>{tournament?.details?.description}</p>
            ) : (
              <p>
                Welcome.
                <br />
                This tournament hosted by passport.
              </p>
            )}
          </div>
        </section>
      ) : (
        <section className={classNames("cupInfoBg", styles.tourDetails)}>
          <div></div>
          <div>
            <h1>
              <FormattedMessage id="battlecup.information.tournamentDetails" />
            </h1>
            {tournament?.details?.description ? (
              <p>{tournament?.details?.description}</p>
            ) : (
              <p>
                Welcome.
                <br />
                This tournament hosted by passport.
              </p>
            )}
          </div>
        </section>
      )}
      {/* organizer */}
      {!!tournament?.details?.organizerName && (
        <section
          className={classNames("cupInfoBg", styles.tournament__organizer)}
        >
          <div>
            {!!tournament?.media?.logo && (
              <img src={tournament?.media?.logo || NoImage} alt="pubg_logo" />
            )}
          </div>

          <div>
            <h1> {tournament?.details?.organizerName}</h1>
          </div>
        </section>
      )}
      {/* PRIZE FOUND */}
      {!!tournament?.details?.prizeFund ? (
        <section className={classNames("cupInfoBg", styles.cashPrize)}>
          <h1>
            <FormattedMessage id="battlecup.information.prizeDetails" />
          </h1>
          {tournament?.details?.prizeFund && tournament?.details?.prizeFund}
        </section>
      ) : (
        <section className={classNames("cupInfoBg", styles.cashPrize)}>
          <h1>
            <FormattedMessage id="battlecup.information.prizeDetails" />
          </h1>
          No Prize Details
        </section>
      )}
      {/* GAME AND FORMAT */}
      <section className={classNames("cupInfoBg", styles.tourGame)}>
        <h1>
          <FormattedMessage id="battlecup.information.gameAndFormat" />
        </h1>
        <ul>
          <li>
            <GameItem
              logo={tournament?.game?.logo}
              title={tournament?.game?.title}
              size={tournament?.size}
            />
            <li>
              <FormattedMessage id="battlecup.information.participantsLimit" />{" "}
              : {tournament?.participantLimit}
            </li>
            <li>Current members count : {tournament?.membersCount}</li>
          </li>
        </ul>
      </section>
      {/* CONTACTS */}
      {!!tournament?.details?.contacts?.length && (
        <section className={classNames("cupInfoBg", styles.tourRules)}>
          <h1>
            <FormattedMessage id="battlecup.information.contacts" />
          </h1>
          <ul>
            {tournament?.details?.contacts?.map((el) => {
              return (
                <li>
                  {el?.name ? el?.name : false}{" "}
                  <a style={{ color: "aqua" }}>
                    {el?.title ? ":" + el?.title : false}
                  </a>
                </li>
              );
            })}
          </ul>
        </section>
      )}
      {/* PARTNER LOGO */}
      {!!tournament?.media?.partnerLogo?.length && (
        <div className={styles.wrapperTournaments}>
          <section className={classNames("cupInfoBg", styles.aboutTournaments)}>
            <h4 className={styles.aboutTitle}>
              <FormattedMessage id="battlecup.information.partners" />
            </h4>
            <article className={styles.organizerTeam}>
              <div className={styles.gamesContainer}>
                {tournament?.media?.partnerLogo?.map((el) => (
                  <img
                    className={styles.organizerImage}
                    src={el}
                    alt="partnerLogo"
                  />
                ))}
              </div>
            </article>
          </section>
        </div>
      )}
      {/* SPONSOR LOGO */}
      {!!tournament?.media?.sponsorLogo?.length && (
        <div className={styles.wrapperTournaments}>
          <section className={classNames("cupInfoBg", styles.aboutTournaments)}>
            <h4 className={styles.aboutTitle}>
              <FormattedMessage id="battlecup.information.sponsors" />
            </h4>
            <article className={styles.organizerTeam}>
              <div className={styles.gamesContainer}>
                {tournament?.media.sponsorLogo?.map((el) => (
                  <img
                    className={styles.organizerImage}
                    src={el}
                    alt="sponsorLogo"
                  />
                ))}
              </div>
            </article>
          </section>
        </div>
      )}
      {/* ORGANIZATOR LOGO */}
      {!!tournament?.media?.organizerLogo?.length && (
        <div className={styles.wrapperTournaments}>
          <section className={classNames("cupInfoBg", styles.aboutTournaments)}>
            <h4 className={styles.aboutTitle}>
              <FormattedMessage id="community.organizer" />
            </h4>
            <article className={styles.organizerTeam}>
              <div className={styles.gamesContainer}>
                {tournament?.media.organizerLogo?.map((el) => (
                  <img
                    className={styles.organizerImage}
                    src={el}
                    alt="organizerLogo"
                  />
                ))}
              </div>
            </article>
          </section>
        </div>
      )}
      {/* Registration */}
      {isAuthenticated() ? (
        requestSuccess ? (
          isUserDataParicipant?.team ||
          isUserDataParicipant?.teamRoster ||
          isUserDataParicipant?.user ? (
            <section className={styles.btnWrapper}>
              <div>
                <Button color="yellow" type="cupbottom">
                  You already registered
                </Button>
              </div>
            </section>
          ) : tournament?.status === "registration" ? (
            <section className={styles.btnWrapper}>
              {hasUserGame() ? (
                <div
                  onClick={() =>
                    checkParticipationTypeData(
                      tournament.participationType,
                      goToUrl
                    )
                  }
                >
                  <Button active color="yellow" type="cupbottom">
                    <FormattedMessage id="battlecup.information.registrationButton" />
                  </Button>
                </div>
              ) : !hasUserGame() ? (
                <div onClick={() => goToUrl("/games")}>
                  <Button active color="red" type="cupbottom">
                    <FormattedMessage id="settings.games.addGame" />
                  </Button>
                </div>
              ) : (
                false
              )}
            </section>
          ) : (
            !userData?.profileInfoFilled && (
              <div onClick={() => goToUrl("/games")}>
                <Button active color="red" type="cupbottom">
                  <FormattedMessage id="battlecup.information.fillProfile" />
                </Button>
              </div>
            )
          )
        ) : (
          <CircularProgress />
        )
      ) : (
        <div onClick={() => goToUrl("/login")}>
          <Button active color="red" type="cupbottom">
            <FormattedMessage id="navigation.login" />
          </Button>
        </div>
      )}
    </div>
  );
}
