import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import { API, API_ROUTER } from "../../../../api";

import noImage from "../../../../assets/images/no-image.webp";
import doneIcon from "../../../../assets/icons/done.svg";

import styles from "./styles.module.scss";
import { ROLE_CAPITAN } from "./constants";

export const MatchMembers = ({ match, isUpdateUserData }) => {
  const history = useHistory();
  const [isLoad, setIsLoad] = useState(false);
  const [members, setMembers] = useState();

  const getNameTeam = (val) => {
    if (val?.team?.name) {
      return val.team.name;
    }

    if (val?.user?.nickname) {
      return val.user.nickname;
    }

    return "-";
  };

  const getMembers = () => {
    const token = localStorage.getItem("token");

    if (token) {
      return getMembersMatch();
    }

    return getMembersPublicMatch();
  };

  const getMembersMatch = () => {
    API.request({
      ...API_ROUTER.tournaments.getMembersMatch,
      pathKeys: {
        matchId: match.matchId,
      },
    })
      .then((res) => {
        setMembers(res);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoad(false));
  };

  const getMembersPublicMatch = () => {
    API.request({
      ...API_ROUTER.tournaments.getMembersPublicMatch,
      pathKeys: {
        matchId: match.matchId,
      },
    })
      .then((res) => {
        setMembers(res);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoad(false));
  };

  const goToUrl = (url) => {
    history.push(url);
  };

  const getMemberUrl = (val) => {
    if (!val) {
      return;
    }

    return goToUrl(`/id/${val?.url}`);
  };

  useEffect(() => {
    getMembers();
  }, [isUpdateUserData]);

  return (
    <div className={classNames("matchMembersWrapperBg", styles.wrapper)}>
      <div className={styles.title}>MATCH MEMBERS</div>
      <div className={styles.teams}>
        {/* <div className={styles.row}>
          <div className={styles.name}>
            {getNameTeam(match?.tournamentMember1)}
          </div>
          <div className={styles.name}>
            {getNameTeam(match?.tournamentMember2)}
          </div>
        </div> */}
        <div className={styles.row}>
          <div className={styles.values}>
            <div className={styles.name}>
              {getNameTeam(match?.tournamentMember1)}
            </div>
            {members?.firstMembers?.map((el, index) => (
              <>
                {!!el && (
                  <div
                    className={classNames(
                      "matchMembersValuesItemBG",
                      styles.item
                    )}
                    key={index}
                    onClick={() => getMemberUrl(el)}
                  >
                    <div className={styles.status}>
                      {el?.isCheckedIn ? (
                        <img
                          src={doneIcon}
                          alt="check-in"
                          className={styles.icon}
                        />
                      ) : (
                        "-"
                      )}
                    </div>
                    <div className={styles.nickname}>
                      {el?.role === ROLE_CAPITAN && (
                        <span className={styles.bold}>
                          <FormattedMessage id="battlecup.registration.form.members.captain" />
                        </span>
                      )}
                      <span>{el?.nickname}</span>
                    </div>
                    <div className={styles.avatarGamer}>
                      <div className={styles.logo}>
                        <img src={el?.avatar || noImage} alt="avatar" />
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>
          <div className={styles.values}>
            <div className={styles.name}>
              {getNameTeam(match?.tournamentMember2)}
            </div>
            {members?.secondMembers?.map((el, index) => (
              <>
                {!!el && (
                  <div
                    className={classNames(
                      "matchMembersValuesItemBG",
                      styles.item
                    )}
                    key={index}
                    onClick={() => getMemberUrl(el)}
                  >
                    <div className={styles.logo}>
                      <img src={el?.avatar || noImage} alt="avatar" />
                    </div>
                    <div className={styles.nickname}>
                      {el?.role === ROLE_CAPITAN && (
                        <span className={styles.bold}>
                          <FormattedMessage id="battlecup.registration.form.members.captain" />
                        </span>
                      )}
                      <span>{el?.nickname}</span>
                    </div>
                    <div className={styles.status}>
                      {el?.isCheckedIn ? (
                        <img
                          src={doneIcon}
                          alt="check-in"
                          className={styles.icon}
                        />
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchMembers;
