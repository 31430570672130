import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { API, API_ROUTER } from "../../../api";

import CupBracket from "../../../components/brackets";
import CupBracketFFA from "../../../components/brackets/bracketFFA";
import CupTop from "../../../components/cup-top";
import GlobalStyles from "../../../assets/styles/global";
import { Container, Preloader } from "../../../components/UI";

export default function CupBracketHOC({ ...props }) {
  const [tournaments, setTournaments] = useState();
  const [tournamentDoesnotExistModal, setTournamentDoesnotExistModal] =
    useState(false);
  const [isLoad, setIsLoad] = useState(false);

  let { id } = useParams();

  const getCurrentTournament = () => {
    setIsLoad(true);

    API.request({
      ...API_ROUTER.tournaments.getCurrentTournament,
      pathKeys: {
        tournamentId: id,
      },
    })
      .then((res) => {
        setTournaments(res);
      })
      .catch((err) =>
        err
          ? setTournamentDoesnotExistModal(true)
          : setTournamentDoesnotExistModal(false)
      )
      .finally(() => setIsLoad(false));
  };
  useEffect(() => {
    getCurrentTournament();
  }, []);

  return (
    <>
      <GlobalStyles />
      <Container>
        <CupTop
          tournament={tournaments}
          tournamentDoesnotExistModal={tournamentDoesnotExistModal}
          {...props}
        />
        {!!isLoad && <Preloader />}
        {/* проверяю на тип турнира */}
        {!isLoad &&
          (tournaments?.stages[0]?.type === "FFA" ? (
            <CupBracketFFA tournament={tournaments} {...props} />
          ) : (
            <CupBracket tournament={tournaments} {...props} />
          ))}
      </Container>
    </>
  );
}
