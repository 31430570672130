import React, { useEffect, useState } from "react";
import { API, API_ROUTER } from "../../../api";
import { useParams } from "react-router-dom";
import CupMatches from "../CupMatches";
import CupTop from "../../../components/cup-top";
import GlobalStyles from "../../../assets/styles/global";
import { Container } from "../../../components/UI";
export default function CupMatchesHOC({ ...props }) {
  const [tournaments, setTournaments] = useState();
  const [tournamentDoesnotExistModal, setTournamentDoesnotExistModal] =
    useState(false);
  let { id } = useParams();
  useEffect(() => {
    API.request({
      ...API_ROUTER.tournaments.getCurrentTournament,
      pathKeys: {
        tournamentId: id,
      },
    })
      .then((res) => {
        setTournaments(res);
      })
      .catch((err) =>
        err
          ? setTournamentDoesnotExistModal(true)
          : setTournamentDoesnotExistModal(false)
      );
  }, []);
  return (
    <>
      <GlobalStyles />
      <Container>
      <CupTop
        tournament={tournaments}
        tournamentDoesnotExistModal={tournamentDoesnotExistModal}
        {...props}
      />
      <CupMatches tournament={tournaments} {...props} />
      </Container>
    </>
    
  );
}
